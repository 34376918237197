import QrScanner from "qr-scanner";
import { useEffect, useRef, useState } from "react";
import useToast from "../utils/toast/useToast.js";
import styles from "../styles/QrReader.module.css";
import sensationLogo from "../assets/sensationLogo.png";
import scanning from "../assets/scanning.png";
import qrcode from "../assets/qrcode.png";
import { getRestaurantById } from "../services/restaurant.js";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const QRScanner = () => {
  const [showScanner, setShowScanner] = useState(true);
  const videoRef = useRef(null);
  const qrScannerRef = useRef(null);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const initializeScanner = async () => {
      try {
        const qrScanner = new QrScanner(videoRef.current, async (result) => {
          window.location.replace(result);
        });
        qrScannerRef.current = qrScanner;

        const cameras = await QrScanner.listCameras();
        if (cameras?.length > 0) {
          qrScanner.start(cameras[0]?.id);
        } else {
          setShowScanner(false);
          toast.showErrorToast("No cameras found");
        }
      } catch (error) {
        console.error("Error initializing QR scanner:", error.message);
      }
    };
    initializeScanner();
    return () => {
      if (qrScannerRef.current) {
        qrScannerRef.current.stop();
      }
    };
  }, []);
  return (
    // <div className={styles.outer}>
    //   <div className={styles.main}>
    //     <div className={styles.welcome}>
    //       <p className={styles.welcomeapp}>Welcome To Menu App</p>
    //       <hr />
    //       {showScanner ? (
    //         <>
    //           <div className={styles.cameraavl}>
    //             {/* <img src={scanning} alt="" className={styles.scanning} /> */}
    //             <video
    //               ref={videoRef}
    //               id="scan"
    //               className={styles.scanner} // Add styling for visibility
    //             ></video>
    //           </div>
    //         </>
    //       ) : (
    //         <img src={qrcode} alt="" className={styles.qr} />
    //       )}
    //     </div>
    //     <div className={styles.footer}>
    //       <p className={styles.PoweredBy}>Powered by</p>
    //       <img
    //         src={sensationLogo}
    //         className={styles.Sensation}
    //         alt="Sensation Logo"
    //       />
    //     </div>
    //   </div>
    // </div>
    <div className={styles.outer}>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <p className={styles.welcomeapp}>Welcome To TraceMenu App</p>
        {
          showScanner ? (
            // <div className={styles.cameraavl}>
            //   <img src={scanning} alt="" className={styles.scanning} />
            <video
              ref={videoRef}
              id="scan"
              className={styles.scanner} // Add styling for visibility
            ></video>
          ) : // </div>

          null
          // <img src={qrcode} alt="" className={styles.qr} />
        }
        <p
          className={styles.welcomeapp}
          style={{ fontSize: "17px", fontWeight: "400", marginTop: "10px" }}
        >
          Align the QR within the frame to scan
        </p>
      </div>
    </div>
  );
};

export default QRScanner;
