import { quantityPrice, spicyLevel } from "../../constants";
import { FaAngleDown } from "react-icons/fa";
import styles from "./CartItem.module.css";
import CustomizeItemPage from "../FoodItemList/CustomizeItemPage/index";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addItem, removeItem, bulkQuantity } from "../../store/cartSlice";
import { motion } from "framer-motion";
import RepeatPrevious from "../FoodItemList/RepeatPrevious";
import { toast } from "react-toastify";

const CartItem = ({ item }) => {
  const [showRepeatPopup, setShowRepeatPopup] = useState(false);
  const dispatch = useDispatch();
  const itemsInCart = useSelector((state) => state.cart.items);
  const [sameItem, setSameItem] = useState(true);
  const [showCustom, setShowCustom] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const [prevQuantity, setPrevQuantity] = useState(0);
  const [counter, setCounter] = useState(item?.quantity);
  const [showCounter, setShowCounter] = useState(counter);
  // console.log(item, "item");
  const handleCustomizePagePopup = () => {
    setShowCustom(true);
    setSameItem(true);
    // setNewItem(false);
  };

  useEffect(() => {
    setPrevQuantity(item.quantity);
  }, []);

  // console.log(prevQuantity);
  const handleEventAdd = () => {
    // item.category_id === foodCategorySlice[0]._id;
    setShowRepeatPopup(true);
    // dispatch(addItem({ ...item, quantity: 1 }));
  };

  const handleEventSub = () => {
    dispatch(removeItem({ ...item, quantity: 1 }));
  };

  useEffect(() => {
    // console.log(item);
    dispatch(bulkQuantity({ item, quantity: showCounter }));
  }, [showCounter]);

  useEffect(() => {
    setCounter(item?.quantity);
  }, [item?.quantity]);
  const handleInputQuantity = (e) => {
    setCounter(e.target.value);
  };

  const handleBlurInput = (e) => {
    if (e.target.value == 0) {
      setCounter(1);
      setShowCounter(1);
    } else {
      setShowCounter(Math.abs(e.target.value));
    }
  };
  return (
    itemsInCart.length > 0 && (
      <div
        className={`${styles.mainDiv} mx-4 bg-white  px-3 py-2 overflow-hidden my-2`}
      >
        <div className={styles.contain}>
          <h3 className={`${styles.itemName} mb-2`}>
            {item.name}{" "}
            <span className="text-black-50">({item?.quantityLevel})</span>
          </h3>

          {/* <h6 className={styles.quantity}>Quantity : {item.quantity}</h6> */}
        </div>

        {item.addOns && item.addOns.length ? (
          <div className={`${styles.mediumLabel} `}>
            {item.addOns.reduce(
              (result, value, index) =>
                result + (index === 0 ? "" : ", ") + value.name.toString(),
              ""
            )}
          </div>
        ) : null}
        {item?.selectedBadges && item?.selectedBadges.length > 0 ? (
          <p className={`${styles.mediumLabel} ${styles.instruction}`}>
            {item?.selectedBadges?.reduce(
              (result, value, index) =>
                result + (index === 0 ? "" : ", ") + value,
              ""
            )}{" "}
          </p>
        ) : null}
        {item?.instructions && (
          <p className={`${styles.mediumLabel} ${styles.instruction}`}>
            {item?.instructions}
          </p>
        )}
        <div className="d-flex justify-content-between align-items-center m-1">
          <div
            className={`${styles.customize} text-center  `}
            onClick={handleCustomizePagePopup}
          >
            Customize
          </div>
          <div className="d-flex justify-content-between ">
            <span className={`${styles.price} mx-4 `}>
              {" "}
              ₹
              {(item && item.total ? item.total : item.basePrice) *
                (item.quantity !== null ? item.quantity : 0)}
            </span>
            <div className={styles.counter}>
              <span onClick={handleEventSub}>-</span>
              <input
                maxLength={3}
                type="number"
                value={counter}
                className={styles.inputQuantity}
                onChange={(e) => handleInputQuantity(e)}
                onBlur={(e) => {
                  handleBlurInput(e);
                }}
                min={1}
                autoComplete="off"
              />
              <span onClick={handleEventAdd}>+</span>
            </div>
          </div>
        </div>
        {showCustom && (
          <CustomizeItemPage
            item={item}
            setShowCustomizePage={setShowCustom}
            sameItem={sameItem}
            itemsInCart={itemsInCart}
          />
        )}
        {showRepeatPopup && (
          <RepeatPrevious
            item={item}
            setShowRepeatPopup={setShowRepeatPopup}
            setShowCustomizePage={setShowCustom}
            setSameItem={setSameItem}
            sameItem={sameItem}
            setNewItem={setNewItem}
          />
        )}
      </div>
    )
  );
};

export default CartItem;
