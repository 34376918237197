import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { IoAlertCircleOutline } from "react-icons/io5";
const CancelOrder = ({
  showPopup,
  handleClose,
  setCancelOrderReason,
  setShowCancelConfirmation,
}) => {
  const [selectedReason, setSelectedReason] = useState("");
  const [customReason, setCustomReason] = useState("");

  const cancellationReasons = [
    "Changed my mind about the order",
    "Delivery time is too long",
    "Ordered by mistake",
    "Payment issues",
    "Wrong items selected",
    "Restaurant taking too long",
    "Other (please specify)",
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const finalReason =
      selectedReason === "Other (please specify)"
        ? customReason
        : selectedReason;
    setCancelOrderReason(finalReason);
    setShowCancelConfirmation(true);
    handleClose();
  };

  return (
    <div>
      <Modal show={showPopup} onHide={handleClose} centered>
        <Modal.Header closeButton className="pb-2">
          <Modal.Title>
            {" "}
            <h2 className="d-flex align-items-center mb-0">
              {" "}
              <IoAlertCircleOutline color="red" className="me-2" />
              <span className="text-lg font-bold text-gray-800">
                Cancel Order
              </span>
            </h2>
            <p className="text-gray fs-6 m-0">
              Please select a reason for cancelling your order.
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-1 px-0">
          <div className="max-w-lg mx-auto mt-8">
            <div>
              {/* <form onSubmit={handleSubmit}> */}
              <div className="space-y-4 px-4">
                {cancellationReasons.map((reason) => (
                  <div key={reason} className="flex items-start gap-2">
                    <input
                      type="radio"
                      id={reason}
                      name="cancellationReason"
                      value={reason}
                      checked={selectedReason === reason}
                      onChange={(e) => setSelectedReason(e.target.value)}
                      className="mt-1"
                    />
                    <label htmlFor={reason} className="ml-2 text-gray-700">
                      {reason}
                    </label>
                  </div>
                ))}

                {selectedReason === "Other (please specify)" && (
                  <div className="mt-2">
                    <textarea
                      value={customReason}
                      onChange={(e) => setCustomReason(e.target.value)}
                      placeholder="Please provide your reason for cancellation..."
                      className="w-100 p-2 border border-gray-300 rounded-md "
                      rows={4}
                      // cols={46}
                      required
                    />
                  </div>
                )}
              </div>
              <Modal.Footer className="mt-1">
                <div className="mt-6 d-flex justify-content-end gap-3">
                  <button
                    type="button"
                    onClick={() => handleClose()}
                    className="px-2 py-1 btn btn-secondary"
                  >
                    Go Back
                  </button>
                  <button
                    type="submit"
                    disabled={!selectedReason}
                    onClick={handleSubmit}
                    className="px-2 py-1 btn btn-danger"
                  >
                    Cancel Order
                  </button>
                </div>
              </Modal.Footer>
              {/* </form> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CancelOrder;
