import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./CurrentOrderStatus.module.css";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import useCartTotals from "../../../hooks/useCartTotal";
import { motion } from "framer-motion";
import {
  getStatusColor,
  getTextByStatus,
  orderStatus,
} from "../../../constants";
import meal from "../../../assets/meal.png";
import SocketContext from "../../../socket/socket";
import notificationSound from "../../../assets/notificationSoundres.wav";
import { fetchOrdersById } from "../../../services/ordersService";

const CurrentOrderStatus = () => {
  const navigate = useNavigate();
  const audioRef = useRef(null);

  const socket = useContext(SocketContext);
  const [order, setOrder] = useState(
    localStorage.getItem("latestOrder")
      ? JSON.parse(
          localStorage?.getItem("latestOrder") !== "undefined"
            ? localStorage.getItem("latestOrder")
            : null
        )
      : null
  );
  const userDetails = localStorage.getItem("userDetails")
    ? localStorage.getItem("userDetails") !== "undefined"
      ? JSON.parse(localStorage.getItem("userDetails"))
      : null
    : null;
  const userId =
    userDetails?._id ||
    localStorage.getItem("guestUserId") ||
    localStorage.getItem("guest_id");

  const itemsInCart = useSelector((state) => state.cart.items);
  const { totalItemPrice, totalQuantity } = useCartTotals(itemsInCart);
  const playAudio = () => {
    if (audioRef && audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    }
  };

  const handleAudioEnd = () => {
    if (audioRef && audioRef.current) {
      audioRef.current.currentTime = 0;
    }
  };
  const handleDeleteOrder = (data) => {
    if (data?.userId === userId && data?.orderNo === order?.orderNo) {
      setOrder(null);
      localStorage.removeItem("latestOrder");
    }
  };
  const handleOrderStatusUpdate = (data) => {
    if (data?.userId === userId && data?.orderNo === order?.orderNo) {
      if (
        ["InProcess", "ready", "cancelled"].includes(
          data?.updatedOrder?.orderStatus
        ) &&
        data?.updatedOrder?.orderStatus !== order?.orderStatus
      ) {
        setTimeout(() => {
          playAudio();
        }, 100);
      }

      if (
        data?.updatedOrder?.orderStatus === "delivered" ||
        data?.updatedOrder?.orderStatus === "cancelled"
      ) {
        localStorage.removeItem("latestOrder");
        setOrder(null);
      } else {
        localStorage.setItem("latestOrder", JSON.stringify(data?.updatedOrder));
        setOrder(data?.updatedOrder);
      }
    }
  };
  useEffect(() => {
    if (userDetails) {
      if (order) {
        if (userId) {
          if (order?.user_id?._id === userId) {
          } else {
            setOrder(null);
            localStorage.removeItem("latestOrder");
          }
        }
      }
    } else {
      if (order) {
        // console.log("order", order, userId);
        if (userId) {
          if (order?.user_id?._id === userId) {
          } else {
            setOrder(null);
            localStorage.removeItem("latestOrder");
          }
        }
      }
    }
  }, [order, userDetails]);
  useEffect(() => {
    socket?.on("deleteOrderUpdate", handleDeleteOrder);
    socket?.on("orderUpdateEvent", handleOrderStatusUpdate);

    // Cleanup function to remove the event listener on component unmount
    return () => {
      socket?.on("deleteOrderUpdate", handleDeleteOrder);
      socket?.off("orderUpdateEvent", handleOrderStatusUpdate);
    };
  }, [socket]);

  useEffect(() => {
    if (order !== null) {
      async function fetchOrder() {
        const response = await fetchOrdersById(order?._id);
        if (response?.orders) {
          response?.orders[0]?.orderStatus === "delivered" ||
          response?.orders[0]?.orderStatus === "cancelled"
            ? setOrder(null)
            : setOrder(response?.orders[0]);
        }
      }
      fetchOrder();
    }
  }, []);
  return order?.orderStatus !== orderStatus.Delivered && order !== null ? (
    <>
      <div
        className={styles.bottomdiv}
        style={{ backgroundColor: `${getStatusColor(order?.orderStatus)}` }}
        onClick={() => {
          navigate("/welcome");
          // playAudio();
        }}
      >
        <div className={styles.orderInfo}>
          <div className={styles.itemadded}>
            <span className="fw-bold">Order No:</span> {order?.orderNo}
          </div>
          <div className={styles.totalbill}>
            <span className="fw-bold"> Status:</span>{" "}
            {getTextByStatus[order?.orderStatus]}
            {/* <span className={styles.price}>{totalItemPrice}</span> */}
          </div>
        </div>
        <Link to={"/welcome"}>
          <img src={meal} alt="" />
          {/* <motion.button className={styles.button} whileTap={{ scale: 0.9 }}>
      Next
    </motion.button> */}
        </Link>
      </div>
      <audio ref={audioRef} src={notificationSound} onEnded={handleAudioEnd} />
    </>
  ) : null;
};

export default CurrentOrderStatus;
