import React from "react";
import { Modal } from "react-bootstrap";

const CancelOrderConfirmation = ({
  showPopup,
  handleCancelOrder,
  setShowCancelConfirmation,
}) => {
  const handleClose = () => setShowCancelConfirmation(false);
  return (
    <div>
      <Modal show={showPopup} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Order Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to cancel the order?</Modal.Body>
        <Modal.Footer>
          <div className="mt-6 d-flex justify-content-end gap-3">
            <button
              type="button"
              className="px-2 py-1 btn btn-secondary"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-2 py-1 btn btn-danger"
              onClick={handleCancelOrder}
            >
              Confirm
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CancelOrderConfirmation;
