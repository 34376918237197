import { useState } from "react";
import styles from "./BillDetail.module.css";
import useCartTotals from "../../hooks/useCartTotal";
import { BsFillInfoCircleFill } from "react-icons/bs";
import TaxDisplayPopup from "../elements/common/TaxDisplayPopup";
import { motion } from "framer-motion";
import { showOrderType } from "../../constants";
const BillDetail = ({ itemsInCart, tableNumber, orderType }) => {
  const { totalItemPrice, total, taxes } = useCartTotals(itemsInCart);
  const tax = JSON.parse(localStorage.getItem("taxes"));
  const [showTaxes, setShowTaxes] = useState(false);
  return (
    <div className="bill-details py-3 mx-4 mb-2">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h5 className={`${styles.heading} fw-bold mx-1`}>
          <span> BILL DETAILS</span>
        </h5>
        <div
          style={{
            height: "27px",
            backgroundColor: "#99a123",
            borderRadius: 5,
          }}
        >
          <span
            className="d-flex align-items-center justify-content-center px-2"
            style={{ color: "#FFF" }}
          >
            {" "}
            {showOrderType[orderType]}
          </span>
        </div>
      </div>
      <div
        className={`${styles.details} border p-3`}
        initial={{ x: "-10", y: "-10", opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1.2 }}
        transition={{ duration: 1 }}
      >
        <p
          className={`${styles.tablename} fs-5 mb-1 text-capitalize`}
          style={{ color: "var(--primary-color)" }}
        >
          {tableNumber === "" ? `${orderType} order` : tableNumber}
        </p>

        <hr style={{ backgroundColor: "rgba(142, 142, 142, 0.59)" }} />

        <div className={`${styles.tablename}`}>
          <div className={styles.flex}>
            <div>Items Total</div>
            <div className={styles.price}>₹{totalItemPrice}</div>
          </div>

          {Boolean(taxes) && (
            <>
              <hr
                style={{
                  backgroundColor: "rgba(142, 142, 142, 0.59)",
                  borderTop: " 1px dashed black",
                }}
              />
              <div className={styles.flex}>
                <div
                  className={styles.taxTitle}
                  onClick={() => setShowTaxes(true)}
                >
                  Taxes <BsFillInfoCircleFill size={17} color="grey" />
                </div>
                <div>₹{taxes.toFixed(2)}</div>
              </div>
            </>
          )}
        </div>
        {showTaxes && (
          <TaxDisplayPopup
            amount={totalItemPrice}
            setShowTaxes={setShowTaxes}
            taxes={taxes}
            showTaxes={showTaxes}
          />
        )}
        <hr style={{ backgroundColor: "rgba(142, 142, 142, 0.59)" }} />

        <div>
          <h6 className="fw-bold">
            To Pay
            <span
              className="float-end  "
              style={{ color: "var(--primary-color)" }}
            >
              ₹{totalItemPrice && total.toFixed(0)}
            </span>
          </h6>
        </div>
      </div>
    </div>
  );
};

export default BillDetail;
