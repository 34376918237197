import styles from "./AskForPayment.module.css";
import { motion } from "framer-motion";
import useToast from "../../../utils/toast/useToast";
import { RxCross2 } from "react-icons/rx";
import { Button } from "../Button";
import { useState } from "react";

const AskForPaymentCard = ({ onClose, handleConfirmOrder }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const toast = useToast();
  const paymentUPI = localStorage.getItem("paymentUPI");
  const handlePayment = () => {
    setShowConfirmation(true);
    window.location.assign(`${paymentUPI}`);
  };
  return (
    <div className={styles.container} onClick={onClose}>
      <motion.div
        className={styles.popup}
        initial={{ translateY: "100%" }}
        animate={{ translateY: "0%" }}
        transition={{ duration: 0.4 }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="w-100 d-flex justify-content-end">
          <RxCross2
            size={22}
            color="black"
            onClick={() => {
              if (showConfirmation) {
                handleConfirmOrder(true, false);
              }
              onClose();
            }}
          />
        </div>
        <hr />
        {paymentUPI && paymentUPI?.length > 0 && (
          <span
            className="w-100"
            style={{
              fontSize: "14px",
              textAlign: "justify",
            }}
          >
            <strong>NOTE: </strong> The payment is being done through installed
            UPI payment app. Tracemenu does not act as intermediate and does not
            store any payment details.
          </span>
        )}
        <div className={`${styles["button-wrapper"]} mt-3`}>
          <div>
            <Button
              value="Pay By Cash"
              style={{
                width: "110px",
                height: "32px",
                borderRadius: "10px",
                marginBottom: "15px",
                color: "#FA4A0C",
                border: "2px solid #FA4A0C",
                backgroundColor: "#F9D9CD",
              }}
              onClick={() => {
                handleConfirmOrder(true, false);
                onClose();
              }}
            />
          </div>

          {paymentUPI && paymentUPI?.length > 0 && (
            <Button
              value="Pay By UPI"
              onClick={handlePayment}
              style={{
                width: "110px",
                height: "32px",
                borderRadius: "10px",
                marginBottom: "15px",
              }}
            />
          )}
        </div>
        {showConfirmation && (
          <span className="d-flex justify-content-center gap-2">
            Please confirm if you have paid the amount{" "}
            <Button
              value="I Paid!"
              onClick={() => {
                // toast.showInfoToast(
                //   "The payment is being done through UPI payment method, Tracemenu does not store or handle the payments made to the restaurants.",
                //   {
                //     closeButton: false,
                //     autoClose: 1000,
                //     icon: (
                //       <span
                //         style={{
                //           fontSize: "20px", // Adjust size if needed
                //           marginTop: "4px", // Add margin to the top
                //           backgroundColor: "#007bff",
                //           color: "white",
                //           display: "flex",
                //           alignItems: "center",
                //           justifyContent: "center",
                //           height: "18px",
                //           width: "18px",
                //           fontSize: "12px",
                //           borderRadius: "50%", // Optional: Adjust icon color
                //         }}
                //       >
                //         i
                //       </span>
                //     ),
                //     bodyStyle: {
                //       display: "flex",
                //       alignItems: "flex-start",
                //     },
                //   }
                // );
                handleConfirmOrder(true);
                onClose();
              }}
              style={{
                width: "70px",
                height: "27px",
                fontSize: "14px",
                borderRadius: "5px",
                borderRadius: "10px",
                marginBottom: "15px",
              }}
              backgroundColor={"green"}
            ></Button>
          </span>
        )}
      </motion.div>
    </div>
    // <>
    //   <div
    //     className={`${styles.modalBackdrop} ${isVisible ? styles.visible : ""}`}
    //     onClick={onClose}
    //   >
    //     {" "}
    //   </div>
    //   <motion.div
    //     className={`${styles.card} ${isVisible ? styles.visible : ""}`}
    //     onClick={(e) => e.stopPropagation()}
    //     initial={{ scale: 0 }}
    //     animate={{ opacity: 1, scale: 1 }}
    //     transition={{ duration: 0.4 }}
    //   >
    //     {" "}
    //     <div className={styles.icon}>
    //       <RxCross2
    //         size={40}
    //         onClick={() => onClose()}
    //         className={styles.exit}
    //       />

    //       {/* <IoCheckmarkCircleOutline size={70} /> */}
    //     </div>
    //     {/* <div className={styles.orderid}>Order Number: {orderNumber}</div>
    //     <div className={styles.tableno}>{tableNo}</div> */}
    //     {/* <div className={styles.ordertext}>
    //       Your order has been received successfully!
    //     </div> */}
    //     <div className={styles.thanktext}>Restaurant is awaiting payment.</div>
    //     <Button
    //       value={"I Paid!"}
    //       backgroundColor={`var(--primary-color)`}
    //       style={{
    //         width: "max-content",
    //         padding: "0px 5px",
    //         marginTop: "10px",
    //       }}
    //       onClick={updateOrder}
    //     ></Button>
    //   </motion.div>
    // </>
  );
};

export default AskForPaymentCard;
