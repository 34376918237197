import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "black";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "black",
    borderBottomWidth: 1,
    // alignItems: "center",
    height: 24,
    fontSize: 12,
    // fontStyle: "bold",
  },
  description: {
    width: "80%",
    textAlign: "right",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 16,
    paddingTop: 5,
  },
  total: {
    width: "20%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 5,
    fontFamily: "Inter",
  },
});

const InvoiceTableFooter = ({ items, order }) => {
  return (
    <View style={styles.row}>
      <Text style={styles.description}>TOTAL</Text>
      <Text style={styles.total}>{`₹${order?.total}`}</Text>
    </View>
  );
};

export default InvoiceTableFooter;
