import API from "./API";

export const fetchOrders = async (resId, userId) => {
  try {
    const response = await API.get(
      `orders/get-orders?restaurant_id=${resId}&user_id=${userId}&status=InProcess&status=received&status=ready`
    );
    return response.data;
  } catch (error) {
    // Handle error
    return [];
  }
};

export const fetchOrdersById = async (Id) => {
  try {
    const response = await API.get(`orders/get-orders?orderId=${Id}`);
    return response.data;
  } catch (error) {
    // Handle error
    return [];
  }
};

export const fetchDeliveredOrders = async (resId, userId) => {
  try {
    const response = await API.get(
      `orders/get-orders?restaurant_id=${resId}&user_id=${userId}`
    );
    return response.data;
  } catch (error) {
    // Handle error
    return [];
  }
};

export const cancelOrder = async ({resId, orderId, cancelOrderReason }) => {
  try {
    const response = await API.patch(`orders/cancel-order/${orderId}`, {
      resId,
      cancelOrderReason,
    });

    return response.data;
  } catch (error) {
    console.error("Cancel Order Error:", error);
    return [];
  }
};
