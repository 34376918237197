import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  taxContainer: {
    marginTop: 12,
  },
  reportTitle: {
    fontSize: 12,
    textAlign: "right",
  },
  bold: {
    fontFamily: "Inter",
  },
});

const InvoiceThankYouMsg = ({ items, orderDetail }) => {
  // const taxes = JSON.parse(localStorage.getItem("taxes"));
  // const calculateTaxes = (total) => {
  //   const cgst = (total * taxes?.cgst) / 100;
  //   const sgst = (total * taxes?.sgst) / 100;
  //   return cgst + sgst;
  // };
  // const total = items.reduce((total, item) => {
  //   return total + item.total * item.quantity;
  // }, 0);
  const total = items.reduce((accumulator, item) => {
    return (
      accumulator +
      ((item?.taxable && item.tax
        ? ((+item?.tax?.cgst + +item?.tax?.sgst) / 100) * item.total
        : 0) +
        item?.total) *
        item.quantity
    );
  }, 0);
  const taxes = items.reduce((accumulator, item) => {
    return (
      accumulator +
      (item?.taxable && item.tax
        ? ((+item?.tax?.cgst + +item?.tax?.sgst) / 100) * item.total
        : 0) *
        item.quantity
    );
  }, 0);
  const calculateDiscount = () => {
    let discountableAmount = 0;
    let taxApplied = 0;
    let amount = 0;
    for (let i = 0; i < items?.length; i++) {
      let item = items[i];
      let itemAddOnCost =
        item?.addOns?.reduce((sum, addon) => sum + addon?.price, 0) ?? 0;
      let itemCost = item?.isDiscountEligible
        ? (item.basePrice + itemAddOnCost) * (item?.quantity ?? 1)
        : 0;

      discountableAmount += itemCost;
    }
    for (let i = 0; i < items?.length; i++) {
      let item = items[i];

      let itemAddOnCost =
        item?.addOns?.reduce((sum, addon) => sum + addon?.price, 0) ?? 0;
      let itemCost = (item?.basePrice + itemAddOnCost) * (item?.quantity ?? 1);
      const itemDiscount = item?.isDiscountEligible
        ? (itemCost / discountableAmount) *
          (orderDetail?.payment?.discount ?? 0)
        : 0;

      const discountedCost = Math.max(itemCost - itemDiscount, 0);

      const taxAmount =
        item?.taxable && item?.tax
          ? ((+item?.tax?.cgst + +item?.tax?.sgst) / 100) * discountedCost
          : 0;
      taxApplied += taxAmount;
      amount +=
        (item?.taxable && item.tax
          ? ((+item?.tax?.cgst + +item?.tax?.sgst) / 100) * itemCost
          : 0) + itemCost;
    }

    return { amount, taxApplied, discountableAmount };
  };
  // console.log(calculateDiscount());
  // const discountAmount = calculateDiscount()?.amount || 0;
  // const discountTax = calculateDiscount()?.taxApplied || 0;
  // const paymentDiscount = orderDetail?.payment?.discount || 0;
  // const calculatedTaxes = taxes || 0;

  // const roundOffAmount = Math.abs(
  //   Math.round(
  //     discountAmount - paymentDiscount - calculatedTaxes + discountTax
  //   ) -
  //     (discountAmount - paymentDiscount - calculatedTaxes + discountTax)
  // );

  return (
    <>
      <View style={styles.taxContainer}>
        <Text style={styles.reportTitle}>
          <Text style={styles.bold}>Subtotal:</Text>{" "}
          {`₹${
            Number.parseFloat(
              calculateDiscount()?.amount -
                orderDetail?.payment?.discount -
                taxes
            ).toFixed(2) ?? 0.0
          }`}
        </Text>
        {/* <Text style={styles.reportTitle}>
          <Text style={styles.bold}>Discount: </Text>₹
          {orderDetail?.payment?.discount
            ? orderDetail?.payment?.discount ?? 0
            : 0}
        </Text> */}
        <Text style={styles.reportTitle}>
          <Text style={styles.bold}>Taxes:</Text> ₹
          {calculateDiscount()?.taxApplied
            ? calculateDiscount()?.taxApplied?.toFixed(2)
            : 0}
        </Text>
        {Math.abs(orderDetail?.payment?.roundedOff) > 0 && (
          <Text style={styles.reportTitle}>
            <Text style={styles.bold}>Round off: </Text>
            {orderDetail?.payment?.roundedOff
              ? orderDetail?.payment?.roundedOff?.toFixed(2)
              : 0}
          </Text>
        )}
        <Text style={styles.reportTitle}>
          <Text style={styles.bold}>Total:</Text> &#x20B9;
          {/* {`${(total + calculateTaxes(total)).toFixed(2)}`} */}
          {orderDetail?.total
            ? `${Math.round(orderDetail?.total).toFixed(2)}`
            : 0.0}
        </Text>
      </View>
    </>
  );
};

export default InvoiceThankYouMsg;
