const calculateDiscountableAmount = (items) => {
  return items?.reduce((total, item) => {
    const itemCost =
      item && item.total ? item.total : item.basePrice * (item?.quantity ?? 1);
    return total + (item?.isDiscountEligible ? itemCost : 0);
  }, 0);
};

export const calculateOrderAmount = (orderItems, totalDiscount = 0) => {
  let newOrder = { total: 0, taxableTotal: 0 };
  let totalTaxAmount = 0;

  // Calculate the discountable amount
  let discountableAmount = 0;
  for (let i = 0; i < orderItems?.length; i++) {
    const item = orderItems[i];
    const itemAddOnCost =
      item?.addOns?.reduce((sum, addon) => sum + addon?.price, 0) ?? 0;

    if (item?.isDiscountEligible) {
      discountableAmount +=
        (item.basePrice + itemAddOnCost) * (item?.quantity ?? 1);
    }
  }

  // Iterate through the items to calculate amounts and taxes
  for (let i = 0; i < orderItems?.length; i++) {
    const item = orderItems[i];
    const itemAddOnCost =
      item?.addOns?.reduce((sum, addon) => sum + addon?.price, 0) ?? 0;

    const itemCost = (item.basePrice + itemAddOnCost) * (item?.quantity ?? 1);

    // Calculate the item's share of the total discount
    const itemDiscount = item?.isDiscountEligible
      ? (itemCost / discountableAmount) * totalDiscount
      : 0;

    // Calculate the discounted cost
    const discountedCost = Math.max(itemCost - itemDiscount, 0);
    console.log({ discountedCost });
    // Calculate tax for the item
    const taxAmount =
      item?.taxable && item?.tax
        ? ((+item?.tax?.cgst + +item?.tax?.sgst) / 100) * discountedCost
        : 0;

    // Update totals
    newOrder.total += itemCost;
    newOrder.taxableTotal += discountedCost + taxAmount;
    totalTaxAmount += taxAmount;
  }

  return {
    newOrderTotalWT: newOrder.total, // Total without tax
    newOrderTotal: newOrder.taxableTotal, // Total with tax
    totalTaxAmount, // Total tax amount
  };
};
