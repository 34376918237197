import { useEffect, useState } from "react";
import { quantityPrice } from "../constants";
import { calculateOrderAmount } from "../utils/orderAmountCalculation";

const useCartTotals = (itemsInCart, discount = 0) => {
  const [totals, setTotals] = useState({
    totalItemPrice: 0,
    total: 0,
    cgst: 0,
    sgst: 0,
    taxes: 0,
    totalQuantity: 0,
  });

  useEffect(() => {
    if (!itemsInCart || !Array.isArray(itemsInCart)) return;
    const { newOrderTotalWT, newOrderTotal, totalTaxAmount } =
      calculateOrderAmount(itemsInCart, discount);

    const totalQuantity = itemsInCart.reduce(
      (total, item) => total + parseInt(item.quantity),
      0
    );

    setTotals({
      totalItemPrice: newOrderTotalWT,
      total: newOrderTotal,
      taxes: totalTaxAmount,
      totalQuantity,
    });
  }, [itemsInCart]);
  return totals;
};

export default useCartTotals;
