import { createSlice } from "@reduxjs/toolkit";

const otpGenerator = () => {
  return Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
};
const otpSlice = createSlice({
  name: "otp",
  initialState: {
    otp: 1234,
  },
  reducers: {
    generate: (state, action) => {
      state.otp = otpGenerator();
      // console.log(`otp generated ${state.otp}`);
      alert(state.otp);
    },
  },
});
export const { generate } = otpSlice.actions;
export default otpSlice.reducer;
