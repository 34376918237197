import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
import ToastProvider from "./utils/toast/ToastProvider";
import Home from "./pages/Home";
import LoginPage from "./pages/LoginPage/LoginPage.jsx";
import WelcomeUserPage from "./pages/WelcomeUserPage";
import MyOrders from "./pages/MyOrders";
import UrlError from "./components/elements/common/UrlError";
import PreviousOrderDetails from "./pages/PreviousOrderDetails";
import NetworkErrPage from "./components/elements/common/NetworkErrPage";
import OtpVerify from "./pages/OtpVerify";
import { useNetworkStatus } from "./hooks/NetworkStatus";
import { PersistGate } from "redux-persist/integration/react";
import QRScanner from "./pages/QrReader.jsx";
import { useEffect } from "react";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "./firebase/firebaseConfig.js";
import { toast } from "react-toastify";
import Message from "./components/Message";
import "react-toastify/dist/ReactToastify.css";
import SplashScreen from "./pages/SplashScreen.jsx";
import UpdateProfile from "./pages/UpdateProfile.jsx";
function App() {
  const isOnline = useNetworkStatus();
  async function requestPermission() {
    try {
      const permission = await Notification.requestPermission();

      if (permission === "granted") {
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_VAPID_KEY,
        });
        localStorage.setItem("notificationtoken", token);
      } else if (permission === "denied") {
        //notifications are blocked
        console.log("You denied for the notification");
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    requestPermission();
  }, []);

  // onMessage(messaging, (payload) => {
  //   // console.log("incoming msg");
  //   // <Message notification={payload.notification} />;
  //   toast(<Message notification={payload.notification} />);
  // });

  return (
    <ToastProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Routes>
              {isOnline ? (
                <>
                  <Route path="/qr" element={<QRScanner />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/order" element={<MyOrders />} />
                  <Route
                    path="/prevorder/:id"
                    element={<PreviousOrderDetails />}
                  />
                  <Route path="/welcome" element={<WelcomeUserPage />} />
                  <Route path="/verify" element={<OtpVerify />} />
                  <Route path="/error" element={<UrlError />} />
                  <Route path="/profile" element={<UpdateProfile />} />
                  <Route path="/" element={<SplashScreen />} />
                  <Route path="/restaurant/:restaurantLink" element={<SplashScreen />} />
                </>
              ) : (
                <Route path="*" element={<NetworkErrPage />} />
              )}
            </Routes>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ToastProvider>
  );
}

export default App;
