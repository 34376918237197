import { createSlice } from "@reduxjs/toolkit";
import { compareAddOns } from "../utils/helperFunction/compareAddons";

const areArraysEqual = (arr1, arr2) => {
  if (arr1?.length !== arr2?.length) return false;
  return arr1?.every((value) => arr2?.includes(value));
};

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: [],
  },
  reducers: {
    addItem: (state, action) => {
      const newItem = action.payload;
      const existingItem = state.items.find(
        (item) =>
          item._id === newItem._id &&
          item.quantityLevel === newItem.quantityLevel &&
          item.instructions === newItem.instructions &&
          areArraysEqual(item?.selectedBadges, newItem?.selectedBadges)
      );

      if (existingItem) {
        existingItem.quantity += newItem.quantity;
      } else {
        state.items.push(newItem);
      }
    },
    removeItem: (state, action) => {
      const itemToRemove = action.payload;
      // console.log({itemToRemove})

      const findMatchingItemIndex = (cartItem) => {
        return (
          cartItem?._id === itemToRemove?._id &&
          cartItem?.quantityLevel === itemToRemove?.quantityLevel &&
          cartItem?.instructions === itemToRemove?.instructions &&
          areArraysEqual(
            cartItem?.selectedBadges,
            itemToRemove?.selectedBadges
          ) &&
          cartItem?.addOns?.length === itemToRemove?.addOns?.length &&
          cartItem?.addOns?.every((oldAddOn) =>
            itemToRemove.addOns?.some(
              (newAddOn) => newAddOn._id === oldAddOn._id
            )
          )
        );
      };

      if (itemToRemove?.instructions === undefined) {
        for (let i = state.items.length - 1; i >= 0; i--) {
          if (state.items[i]._id === itemToRemove._id) {
            if (state.items[i].quantity > 1) {
              state.items[i].quantity -= 1;
            } else {
              state.items.splice(i, 1);
            }
            return;
          }
        }
      } else {
        const itemIndex = state.items.findIndex(findMatchingItemIndex);

        if (itemIndex !== -1) {
          if (state.items[itemIndex].quantity > 1) {
            state.items[itemIndex].quantity -= 1;
          } else {
            state.items.splice(itemIndex, 1);
          }
        }
      }
    },
    updateItem: (state, action) => {
      const updatedItem = action.payload;

      const existingItemIndex = state.items.findIndex((item) => {
        if (
          item._id === updatedItem._id &&
          item.quantityLevel === updatedItem.quantityLevel &&
          item.instructions === updatedItem.instructions &&
          areArraysEqual(item.selectedBadges, updatedItem.selectedBadges)
        ) {
          if (item.addOns?.length !== updatedItem.addOns?.length) {
            return false;
          }

          const addOnsMatch =
            item.addOns?.every((oldAddOn) =>
              updatedItem.addOns?.some(
                (newAddOn) => newAddOn._id === oldAddOn._id
              )
            ) &&
            updatedItem.addOns?.every((newAddOn) =>
              item.addOns?.some((oldAddOn) => oldAddOn._id === newAddOn._id)
            );

          return addOnsMatch;
        }
        return false;
      });

      if (existingItemIndex !== -1) {
        state.items[existingItemIndex].quantity += 1;
      } else {
        state.items.push({ ...updatedItem, quantity: 1 });
      }
    },
    updateInstruction: (state, action) => {
      const {
        Olditem,
        quantityLevel,
        instructions,
        total,
        basePrice,
        addOns,
        selectedBadges,
      } = action.payload;

      let existingItemIndex = state.items.findIndex(
        (item) =>
          item._id === Olditem._id &&
          item.quantityLevel === quantityLevel &&
          item.instructions === instructions &&
          areArraysEqual(item.selectedBadges, selectedBadges) &&
          compareAddOns(item.addOns, addOns)
      );

      if (existingItemIndex !== -1) {
        state.items[existingItemIndex].quantity += Olditem.quantity;
        state.items = state.items.filter(
          (item, index) =>
            index === existingItemIndex || item._id !== Olditem._id
        );
      } else {
        let currentItemIndex = state.items.findIndex(
          (item) =>
            item._id === Olditem._id &&
            item.quantityLevel === Olditem.quantityLevel &&
            item.instructions === Olditem.instructions &&
            areArraysEqual(item.selectedBadges, Olditem.selectedBadges) &&
            compareAddOns(item.addOns, Olditem.addOns)
        );
        if (currentItemIndex !== -1) {
          state.items[currentItemIndex] = {
            ...state.items[currentItemIndex],
            basePrice: basePrice,
            total: total,
            instructions: instructions,
            quantityLevel: quantityLevel,
            selectedBadges: selectedBadges,
            addOns: addOns,
            quantity: Olditem.quantity,
          };
        } else {
          state.items.push({ ...Olditem, quantity: 1 });
        }
      }
    },
    bulkQuantity: (state, action) => {
      const { item, quantity } = action.payload;

      const itemIndex = state.items.findIndex((cartItem) => {
        if (item.instructions === undefined) {
          return cartItem._id === item._id;
        }
        return (
          cartItem._id === item._id &&
          cartItem.instructions === item.instructions &&
          cartItem.quantityLevel === item.quantityLevel &&
          areArraysEqual(cartItem.selectedBadges, item.selectedBadges) &&
          cartItem.addOns?.length === item.addOns?.length &&
          cartItem.addOns?.every((oldAddOn) =>
            item.addOns?.some((newAddOn) => newAddOn._id === oldAddOn._id)
          )
        );
      });

      if (itemIndex !== -1) {
        state.items[itemIndex].quantity = parseInt(quantity);
      }
    },
    repeatItem: (state, action) => {
      const repeatedItem = action.payload;

      if (repeatedItem.instructions || repeatedItem.instructions === "") {
        const itemIndex = state.items.findIndex(
          (cartItem) =>
            cartItem?._id === repeatedItem?._id &&
            cartItem?.instructions === repeatedItem?.instructions &&
            areArraysEqual(
              cartItem?.selectedBadges,
              repeatedItem?.selectedBadges
            ) &&
            cartItem?.quantityLevel === repeatedItem?.quantityLevel &&
            cartItem?.addOn === repeatedItem?.addOn
        );

        if (itemIndex !== -1) {
          state.items[itemIndex].quantity += 1;
        }
      } else {
        const reverseItems = [...state.items].reverse();
        const lastIndex = reverseItems.findIndex(
          (cartItem) => cartItem._id === repeatedItem._id
        );

        if (lastIndex !== -1) {
          const originalIndex = state.items.length - lastIndex - 1;
          state.items[originalIndex].quantity += 1;
        }
      }
    },
    clearCart: (state) => {
      state.items = [];
    },
  },
});

export const {
  addItem,
  removeItem,
  updateItem,
  clearCart,
  repeatItem,
  updateInstruction,
  bulkQuantity,
} = cartSlice.actions;

export default cartSlice.reducer;
