import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTableRow from "./InvoiceTableRow";
import InvoiceTableBlankSpace from "./InvoiceTableBlankSpace";
import InvoiceTableFooter from "./InvoiceTableFooter";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "black",
  },
});

const InvoiceItemsTable = ({ invoice }) => {
  const [items, setItems] = useState([]);
  const [orderData, setOrderData] = useState({
    total: 0,
    tax: 0,
    subTotal: 0,
  });
  const calculateItems = (item, discountableAmount) => {
    let itemAddOnCost =
      item?.addOns?.reduce((sum, addon) => sum + addon?.price, 0) ?? 0;

    let itemCost = (item.basePrice + itemAddOnCost) * (item?.quantity ?? 1);

    const itemDiscount = item?.isDiscountEligible
      ? (itemCost / discountableAmount) *
        (invoice?.orderDetail?.payment?.discount ?? 0)
      : 0;

    const discountedCost = Math.max(itemCost - itemDiscount, 0);

    const taxAmount =
      item?.taxable && item?.tax
        ? ((+item?.tax?.cgst + +item?.tax?.sgst) / 100) * discountedCost
        : 0;

    // console.log("ITEM:  ", {
    //   name: item.name,
    //   item,
    //   isDiscountEligible: item?.isDiscountEligible,
    //   itemCost: itemCost,
    //   discountableAmount: discountableAmount,
    //   itemDiscount: itemDiscount,
    //   d: invoice?.orderDetail?.payment?.discount,
    //   discountedCost: discountedCost,
    // });
    return {
      name: item.name,
      basePrice: item.basePrice,
      quantity: item.quantity,
      addOns: item.addOns,
      discount: itemDiscount,
      tax: taxAmount,
      total: discountedCost,
    };
  };

  useEffect(() => {
    let discountableAmount = 0;
    for (let i = 0; i < invoice?.items?.length; i++) {
      let item = invoice?.items[i];
      let itemAddOnCost =
        item?.addOns?.reduce((sum, addon) => sum + addon?.price, 0) ?? 0;
      let itemCost = item?.isDiscountEligible
        ? (item.basePrice + itemAddOnCost) * (item?.quantity ?? 1)
        : 0;
      discountableAmount += itemCost;
    }
    const itemData = invoice?.items
      ? invoice?.items?.map((item) => calculateItems(item, discountableAmount))
      : [];

    setItems(itemData);
  }, [invoice]);
  return (
    <View style={styles.tableContainer}>
      <InvoiceTableHeader />
      <InvoiceTableRow items={items} order={invoice?.orderDetail} />
      {/* <InvoiceTableBlankSpace rowsCount={invoice.items.length} /> */}
      {/* <InvoiceTableFooter items={invoice.items} order={invoice?.orderDetails} /> */}
    </View>
  );
};

export default InvoiceItemsTable;
