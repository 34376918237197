import styles from "./OrderSuccessful.module.css";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import confettiImg from "../../../assets/confetti.png";
import { motion } from "framer-motion";
import { RxCross2 } from "react-icons/rx";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const OrderSuccessCard = ({ isVisible, onClose, orderId, tableNo }) => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== "/welcome") {
      const timer = setTimeout(() => {
        onClose();
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [location.pathname]);
  return (
    <>
      <div
        className={`${styles.modalBackdrop} ${isVisible ? styles.visible : ""}`}
        onClick={onClose}
      >
        {" "}
      </div>
      <motion.div
        className={`${styles.card} ${isVisible ? styles.visible : ""}`}
        onClick={(e) => e.stopPropagation()}
        initial={{ scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.4 }}
      >
        {" "}
        <div className={styles.icon}>
          <RxCross2
            size={40}
            onClick={() => onClose()}
            className={styles.exit}
          />

          <IoCheckmarkCircleOutline size={70} />
        </div>
        <img src={confettiImg} alt="" className={styles.confetti} />
        <div className={styles.ordertext}>Order Successful</div>
        <div className={styles.thanktext}>Thank you so much for your order</div>
        <div className={styles.orderid}>Order Number: {orderId}</div>
        <div className={styles.tableno}>{tableNo}</div>
      </motion.div>
    </>
  );
};

export default OrderSuccessCard;
