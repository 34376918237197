import { toast } from "react-toastify";

const useToast = () => {
  const showToast = (message, options = {}) => {
    toast.dismiss();
    toast(message, {
      ...options,
      position: "top-center",
      autoClose: 2000,
      style: {
        fontSize: "14px",
        padding: "0px 0px",
        minWidth: "200px",
        width: "auto",
        minHeight: "55px",
      },
      hideProgressBar: true,
    });
  };

  const showInfoToast = (message, options = {}) => {
    showToast(message, { type: "info", ...options });
  };

  const showSuccessToast = (message, options = {}) => {
    showToast(message, { type: "success", ...options });
  };

  const showWarningToast = (message, options = {}) => {
    showToast(message, { type: "warning", ...options });
  };

  const showErrorToast = (message, options = {}) => {
    showToast(message, { type: "error", ...options });
  };

  const showDefaultToast = (message) => {
    showToast(message);
  };

  return {
    showInfoToast,
    showSuccessToast,
    showWarningToast,
    showErrorToast,
    showDefaultToast,
  };
};

export default useToast;
