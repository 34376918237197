import React, { useEffect, useState } from "react";
import styles from "./FoodList.module.css";
import FoodListItem from "../FoodListItem";
import { motion } from "framer-motion";
import ConfirmItem from "../../elements/common/ConfirmItem";
import { useDispatch, useSelector } from "react-redux";
import useCartTotals from "../../../hooks/useCartTotal";
import { fetchFoodCategory } from "../../../store/foodCategorySlice";
import CurrentOrderStatus from "../../elements/common/CurrentOrderStatus";
import { useNavigate } from "react-router-dom";

const FoodList = ({
  foodData,
  foodCategorySlice,
  search,
  setSelectedCategory = () => {},
  selectedCategory,
  setShowMenu = () => {},
  showMenuHeader,
  setshowMenuHeader = () => {},
  setIsMenuFocused = () => {},
}) => {
  const itemsInCart = useSelector((state) => state.cart.items);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { totalQuantity } = useCartTotals(itemsInCart);
  const [showConfirmOrder, setShowConfirmOrder] = useState(
    localStorage.getItem("showConfirmOrder") === "true"
  );
  const [lastScrollY, setLastScrollY] = useState(0);
  useEffect(() => {
    dispatch(fetchFoodCategory());
  }, []);
  // console.log(foodData);
  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();

      const targetId = this.getAttribute("href").substring(1);
      const targetElement = document.getElementById(targetId);

      if (targetElement) {
        const offset = 50; // Adjust this value for the desired extra space
        const elementPosition =
          targetElement.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    });
  });
  // console.log(foodCategorySlice, foodData);
  const handleSelectedCategory = (cat) => {
    const category = foodCategorySlice?.find((item) => item._id == cat);
    setSelectedCategory(category);
  };

  const controlNavbar = () => {
    if (window.scrollY > 20) {
      // if scroll down hide the navbar
      setshowMenuHeader(true);
    } else {
      // if scroll up show the navbar
      setshowMenuHeader(false);
      setShowMenu(false);
      setIsMenuFocused(false);
    }

    // remember current page location to use in the next move
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);

    // cleanup function
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      const targetId = selectedCategory?._id;
      navigate(`#${targetId}`, { replace: true });

      // Smooth scroll after navigation
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        const offset = 50; // Adjust for desired extra space
        const elementPosition =
          targetElement.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  }, []);

  return (
    <div
      className={`${search ? styles.search : styles.store} active ${
        showMenuHeader && "hidden"
      }`}
      onClick={(e) => {
        e.stopPropagation();
        setShowMenu(false);
      }}
    >
      {!search ? (
        <div className={styles.CategoryMenu}>
          {foodCategorySlice?.map((cat, index) => {
            const isSelected =
              selectedCategory?._id === cat?._id ||
              (!selectedCategory && index === 0);
            return (
              <a
                href={`#${cat?._id}`}
                // key={cat?._id}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelectedCategory(cat?._id);
                }}
                className={`${styles.catName} text-decoration-none text-black ${
                  isSelected ? `${styles.selected}` : ""
                }`}
              >
                {cat?.name}
              </a>
            );
          })}
        </div>
      ) : null}

      {foodCategorySlice?.length > 0 &&
        foodCategorySlice.map((cat) => {
          const hasSubCategoriesWithItems = cat?.subCategory?.some((subCat) =>
            foodData?.some((item) => item?.category?._id === subCat?._id)
          );
          const hasCategroyWithItems = foodData?.some(
            (item) => item?.category?._id === cat?._id
          );

          return (
            <React.Fragment key={cat._id}>
              {(hasSubCategoriesWithItems || hasCategroyWithItems) && (
                <div
                  id={cat._id}
                  className="bg-white ms-3 fw-bold fs-3 pt-3 text-uppercase underlineStyle text-black"
                  // style={{ color: "#1c93ce" }}
                >
                  <span>{cat?.name}</span>
                </div>
              )}
              {hasCategroyWithItems && (
                <React.Fragment key={cat?._id}>
                  {foodData
                    ?.filter((item) => item?.category?._id === cat?._id)
                    .map((item) => (
                      <div key={item?._id}>
                        <FoodListItem
                          id={`${item?._id}`}
                          item={item}
                          foodCategorySlice={foodCategorySlice}
                          setShowConfirmOrder={setShowConfirmOrder}
                          showConfirmOrder={showConfirmOrder}
                        />
                      </div>
                    ))}
                </React.Fragment>
              )}
              {cat?.subCategory?.map((subCat) => {
                const hasItems = foodData?.some(
                  (item) => item?.category?._id === subCat?._id
                );

                return (
                  <React.Fragment key={subCat?._id}>
                    {hasItems && (
                      <div
                        id={subCat?._id}
                        className={`cust-sub-catagery-title bg-white ps-3 text-capitalize pt-2 ${styles.cutSubCategory}`}
                      >
                        {subCat?.name}
                      </div>
                    )}

                    {foodData
                      ?.filter((item) => item?.category?._id === subCat?._id)
                      .map((item) => (
                        <div key={item?._id}>
                          <FoodListItem
                            id={`${item?._id}`}
                            item={item}
                            foodCategorySlice={foodCategorySlice}
                            setShowConfirmOrder={setShowConfirmOrder}
                            showConfirmOrder={showConfirmOrder}
                          />
                        </div>
                      ))}
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          );
        })}
      {showConfirmOrder && Boolean(totalQuantity) && <ConfirmItem />}
      {!Boolean(totalQuantity) && <CurrentOrderStatus />}
    </div>
  );
};

export default FoodList;
